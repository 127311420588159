<!---<div class="one-header-top">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-3">
                <div class="header-social">
                    <ul>
                        <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="header-partner">
                    <p>We recognize this is a difficult time for many people. <a href="#">Become a Partner</a></p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="header-call">
                    <ul>
                        <li>
                            <span>Call Us:</span>
                            <a href="tel:+4234543123678">+4 234-543 123 678</a>
                        </li>
                        <li>
                            <span>Mail Us:</span>
                            <a href="mailto:info@levi.com">info@levi.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>-->

<nav class="navbar navbar-expand-lg navbar-light sticky-top">
    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo" class="img-logo"></a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="#home">Inico</a></li>

            <li class="nav-item"><a class="nav-link" href="#about">Nosotros</a></li>

            <li class="nav-item"><a class="nav-link" href="#services">Servicios</a></li>

            <li class="nav-item"><a class="nav-link" href="#team">Equipo</a></li>

            <li class="nav-item"><a class="nav-link" href="#contact">Contactenos</a></li>
        </ul>
    </div>

    <!--<div class="side-nav">
        <a class="side-nav-left" routerLink="/">Sign In</a>
        <a class="side-nav-right cd-signup" routerLink="/"><i class='bx bx-link-external'></i>Sign Up</a>
    </div>-->
</nav>

<div id="home" class="one-banner-area">
    <div class="banner-shape">
        <img src="assets/img/home-one/banner-shape1.png" alt="Banner">
        <!--<img src="assets/img/home-one/banner-shape2.png" alt="Banner">-->
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="banner-text">
                    <div class="banner-video">
                        <a class="popup-youtube"><i class='bx bx-user' ></i></a>
                        <span>Coode</span>
                    </div>

                    <h1>Transformando ideas en software de calidad</h1>

                    <span>Somos expertos en consultoría de software, desarrollo a medida y arquitectura de nube. Impulsamos organizaciones tradicionales y emergentes (startup) a través de la innovación y para el éxito de tu negocio.<br></span>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="banner-slider owl-theme owl-carousel">
                    <div class="banner-inner banner-img-one">
                        <img src="assets/img/home-one/banner-main-shape.png" alt="Banner">
                    </div>

                    <div class="banner-inner banner-img-two">
                        <img src="assets/img/home-one/banner-main-shape.png" alt="Banner">
                    </div>

                    <div class="banner-inner banner-img-three">
                        <img src="assets/img/home-one/banner-main-shape.png" alt="Banner">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="about" class="one-about-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/home-one/about.png" alt="About">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="one-section-title">
                        <span class="sub-title">SOBRE NUESTRA COMPAÑÍA</span>
                        <h2>¿Por qué nos deberías elegir?</h2>
                    </div>

                    <div class="about-tablist">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-default active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                                    <i class='bx bx-bullseye' ></i>
                                     Misión y Visión
                                </a>
                            </li>

                            <li class="nav-item" role="presentation">
                                <a class="nav-default" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
                                    <i class='bx bx-show-alt'></i>
                                    Valores
                                </a>
                            </li>

                            <li class="nav-item" role="presentation">
                                <a class="nav-default" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">
                                    <i class='bx bx-pyramid'></i>
                                    Metas
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <p>Nuestra misión es facilitar tu éxito digital de forma segura</p>
                                <p>Nuestra visión es ser tu socio tecnológico de confianza.</p>
                            </div>

                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <p>Transparencia, excelencia técnica y colaboración son los pilares de nuestra cultura.</p>
                            </div>

                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <p>Impulsar el desarrollo de empresas emergentes, “startups” y apoyar a las empresas tradicionales en su renovación tecnológica, generando impacto en la industria y logrando crecer orgánicamente como organización.</p>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="one-counter-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="counter-item">
                    <h3>10</h3>
                    <p>AÑOS DE EXPERIENCIA </p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="counter-item">
                    <h3>8</h3>
                    <p>PROYECTOS NACIONALES</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="counter-item">
                    <h3>30</h3>
                    <p>CLIENTE EN TODO EL MUNDO</p>
                </div>
            </div>
           
        </div>
    </div>
</div>-->

<div id="team" class="one-team-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">NUESTRO EQUIPO</span>
            <p>Nuestro equipo cuenta con profesionales y amplia experiencia en la industria tecnológica, colaborando con grandes empresas en proyectos internacionales. Ahora, llevamos ese conocimiento a la ejecución de proyectos, adoptando un enfoque organizativo inspirado en modelos de la industria, como el propuesto por Spotify. Esta estrategia nos permite aprovechar las mejores prácticas y metodologías, garantizando la eficiencia y el éxito en todas nuestras iniciativas, nos impulsa a seguir creciendo y adaptándonos a los desafíos cambiantes del mundo digital.</p>
        </div>

        <div class="row">
            <img src="assets/img/team.png" alt="">           
        </div>
    </div>
</div>

<section id="services" class="one-service-area ptb-100">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">LO QUE OFRECEMOS</span>
            <h2>Nuestros servicios</h2>
            <p>Maximiza tu potencial tecnológico con nuestra consultoría experta. Diseñamos y desarrollamos soluciones a la medida a través de equipos de alto desempeño para impulsar tu negocio de forma ágil. Aprovecha la potencia de la nube para escalar sus operaciones.</p>
        </div>

        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a class="nav-item nav-default active" id="nav-home-tab" (click)="cambiarImagen('fabrica')" data-bs-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                    <div class="service-item">
                        <i><img [src]="imagenFabri"></i>
                        <span>Fábrica de Desarrollo</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-profile-tab" (click)="cambiarImagen('consultoria')" data-bs-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                    <div class="service-item">
                        <i><img [src]="imagenConsul"></i>
                        <span>Consultoría de software</span>
                    </div>
                </a>
                
                <a class="nav-item nav-default" id="nav-contact-tab" (click)="cambiarImagen('microservicio')" data-bs-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">
                    <div class="service-item">
                        <i><img [src]="imagenMicro"></i>
                        <span>Microservicios y Monolítos</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-x-tab" (click)="cambiarImagen('arquitectura')" data-bs-toggle="tab" href="#nav-x" role="tab" aria-controls="nav-x" aria-selected="false">
                    <div class="service-item">
                        <i><img [src]="imagenArquit"></i>
                        <span>Arquitecturas de Nube</span>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-y-tab" (click)="cambiarImagen('dev')" data-bs-toggle="tab" href="#nav-y" role="tab" aria-controls="nav-y" aria-selected="false">
                    <div class="service-item">
                        <i><img [src]="imagenDev"></i>
                        <span>DevSecOps:</span>
                        <br>
                    </div>
                </a>

                <a class="nav-item nav-default" id="nav-z-tab" (click)="cambiarImagen('uxui')" data-bs-toggle="tab" href="#nav-z" role="tab" aria-controls="nav-z" aria-selected="false">
                    <div class="service-item">
                        <i><img [src]="imagenUxUi"></i>
                        <span>UX/UI</span>
                        <br>
                    </div>
                </a>
            </div>
        </nav>
            
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/banner-main1.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Fabrica de Desarrollo</h2>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i>Metodologías ágiles (Scrum, Kanban)</li>
                                    <li><i class='bx bxs-chevrons-right'></i>Automatización de Procesos</li>
                                    <li><i class='bx bxs-chevrons-right'></i>Contenedores (Docker)</li>
                                    <li><i class='bx bxs-chevrons-right'></i>Orquestación de Contenedores (Kubernetes)</li>
                                    <li><i class='bx bxs-chevrons-right'></i>Desarrollo Frontend y Backend</li>
                                    <li><i class='bx bxs-chevrons-right'></i>Lenguajes de Programación (Java, Python, JavaScript, etc.)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/banner-main2.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Consultoría de Software</h2>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Análisis de Requisitos y Documentación</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Evaluación de Arquitectura de Software</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Auditoría de Código</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Mejora de Procesos de Desarrollo</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Estrategias de Migración Tecnológica</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Optimización de Costos en TI</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Desarrollo de Hojas de Ruta Tecnológicas</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/contact.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Microservicios y Monolitos:</h2>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Diseño de Arquitectura de Microservicios</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Descomposición de Monolitos en Microservicios</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Gestión de API</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Escalabilidad y Tolerancia a Fallos</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Balanceo de Carga y Gestión de Tráfico</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Contenedores y Orquestación</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Monitoreo y Diagnóstico</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-x" role="tabpanel" aria-labelledby="nav-x-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/faq-men.png" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>Arquitecturas de Nube (AWS, Oracle Cloud, y Azure):</h2>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Selección de Proveedor de Nube</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Diseño de Infraestructura en la Nube</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Migración a la Nube</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Seguridad en la Nube</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Servicios de Almacenamiento y Bases de Datos en la Nube</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Costos y Facturación en la Nube</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Servicios Específicos de AWS, Oracle Cloud y Azure</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-y" role="tabpanel" aria-labelledby="nav-y-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/advisor.jpg" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>DevSecOps:</h2>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> DevOps en la Nube</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Integración Continua (CI) y Entrega Continua (CD)</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Integración de Seguridad en el Ciclo de Desarrollo</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Automatización de Pruebas de Seguridad</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Evaluación de Vulnerabilidades</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Monitoreo de Seguridad Continuo</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Prácticas de Autenticación y Autorización</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Cumplimiento Regulatorio</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-z" role="tabpanel" aria-labelledby="nav-z-tab">
                <div class="service-advisor">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="advisor-img">
                                <img src="assets/img/home-one/about.png" alt="Advisor">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="advisor-content">
                                <h2>UX/UI:</h2>
                                <ul>
                                    <li><i class='bx bxs-chevrons-right'></i> Investigación de Usuarios</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Diseño de Experiencia de Usuario (UX)</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Diseño de Interfaz de Usuario (UI)</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Pruebas de Usabilidad</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Prototipado y Wireframing</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Diseño Responsivo</li>
                                    <li><i class='bx bxs-chevrons-right'></i> Accesibilidad y Diseño Inclusivo</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<section class="one-help-area ptb-100">
    <div class="help-img">
        <img src="assets/img/home-one/help.png" alt="Help">
    </div>

    <div class="container">
        <div class="help-item">
            <div class="one-section-title">
                <span class="sub-title">CREA TU PROPIA IDEA</span>
                <h2>Estamos aquí para ayudarte</h2>
            </div>
            <ul>
                <li>
                    <div class="help-icon">
                        <i class='bx bx-brain'></i>
                    </div>
                    <div class="help-inner">
                        <h3>Make it Your Dream</h3>
                        <p>When you own your own homet seeturlit sed dolor sit am</p>
                    </div>
                </li>
                <li>
                    <div class="help-icon">
                        <i class='bx bx-home-alt'></i>
                    </div>
                    <div class="help-inner">
                        <h3>Create A Place</h3>
                        <p>You can grow in your new homeit amet seeturlit sed dolor sit </p>
                    </div>
                </li>
                <li>
                    <div class="help-icon">
                        <i class='bx bx-brain'></i>
                    </div>
                    <div class="help-inner">
                        <h3>Be A Part Of Community</h3>
                        <p>Buying a home is an investmemet seeturlit sed dolo ameol</p>
                    </div>
                </li>
                <li>
                    <div class="help-icon">
                        <i class='bx bx-home-alt'></i>
                    </div>
                    <div class="help-inner">
                        <h3>Pay Principal</h3>
                        <p>Gain home equity by paying sit amet seeturlit sed dolor sit </p>
                    </div>
                </li>
                <li>
                    <div class="help-icon">
                        <i class='bx bx-brain'></i>
                    </div>
                    <div class="help-inner">
                        <h3>Get Tax Benefits</h3>
                        <p>Take advantage of tax benefitsit amet seeturlit sed dolor sit </p>
                    </div>
                </li>
                <li>
                    <div class="help-icon">
                        <i class='bx bx-home-alt'></i>
                    </div>
                    <div class="help-inner">
                        <h3>Build Credit</h3>
                        <p>Our plan dolor sit amet seeturlit sed dolor sit ameolor sit </p>
                    </div>
                </li>
            </ul>
            <div class="advisor-link">
                <a routerLink="/">GET STARTED</a>
                <i class="bx bx-right-arrow-alt"></i>
            </div>
        </div>
    </div>
</section>-->

<!--<div class="one-among-area ptb-100">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">POR QUÉ ELEGIRNOS</span>
            <h2>Entre ellos estamos</h2>
            <p>Aqui va el texto de el por que elegirnos a nostros como empresa</p>
        </div>

        <div class="among-slider owl-theme owl-carousel">
            <div class="among-item">
                <div class="among-top">
                    <img src="assets/img/home-one/among-one.jpg" alt="Among">
                    <div class="among-icon">
                        <i class='bx bxs-badge-dollar'></i>
                    </div>
                </div>
                <div class="among-bottom">
                    <h3>Fabrica de desarrollo</h3>
                    <p>Texto de prueba del por que elegirnos</p>
                    <a routerLink="/">LEER MÁS <i class="bx bx-right-arrow-alt"></i></a>
                </div>
            </div>            
            <div class="among-item">
                <div class="among-top">
                    <img src="assets/img/home-one/among-two.jpg" alt="Among">
                    <div class="among-icon">
                        <i class='bx bx-bulb'></i>
                    </div>
                </div>
                <div class="among-bottom">
                    <h3>Delivering A Great Customer Experience</h3>
                    <p>Your home equity by paying sit amet seeturlit sed dolor se equity</p>
                    <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                </div>
            </div>

            <div class="among-item">
                <div class="among-top">
                    <img src="assets/img/home-one/among-three.jpg" alt="Among">
                    <div class="among-icon">
                        <i class='bx bx-money'></i>
                    </div>
                </div>
                <div class="among-bottom">
                    <h3>Trusted Home Financing Experts</h3>
                    <p>Srt home equity by paying sit amet seeturlit sed dolor se equity</p>
                    <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                </div>
            </div>

            <div class="among-item">
                <div class="among-top">
                    <img src="assets/img/home-one/among-one.jpg" alt="Among">
                    <div class="among-icon">
                        <i class='bx bxs-badge-dollar'></i>
                    </div>
                </div>
                <div class="among-bottom">
                    <h3>Refinancing Your Home No Doubt About Anything</h3>
                    <p>Gain home equity by paying sit amet seeturlit sed dolor se equity</p>
                    <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                </div>
            </div>

            <div class="among-item">
                <div class="among-top">
                    <img src="assets/img/home-one/among-two.jpg" alt="Among">
                    <div class="among-icon">
                        <i class='bx bx-bulb'></i>
                    </div>
                </div>
                <div class="among-bottom">
                    <h3>Delivering A Great Customer Experience</h3>
                    <p>Your home equity by paying sit amet seeturlit sed dolor se equity</p>
                    <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                </div>
            </div>

            <div class="among-item">
                <div class="among-top">
                    <img src="assets/img/home-one/among-three.jpg" alt="Among">
                    <div class="among-icon">
                        <i class='bx bx-money'></i>
                    </div>
                </div>
                <div class="among-bottom">
                    <h3>Trusted Home Financing Experts</h3>
                    <p>Srt home equity by paying sit amet seeturlit sed dolor se equity</p>
                    <a routerLink="/">READ MORE <i class="bx bx-right-arrow-alt"></i></a>
                </div>
            </div>            
        </div>
    </div>
</div>-->

<!--<div class="one-quote-area">
    <div class="container">
        <div class="row m-0">
            <div class="col-lg-6 p-0">
                <div class="quote-content">
                    <h3>Obtener cotización</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing eltsed do eiusmod tempor incididunt ut labore et dolore </p>
                    <a routerLink="/"><i class='bx bxs-quote-alt-right'></i> Get A Quote</a>
                </div>
            </div>

            <div class="col-lg-6 p-0">
                <div class="quote-img">
                    <img src="assets/img/home-one/quote.jpg" alt="Quote">
                </div>
            </div>
        </div>
    </div>
</div>-->

<!--<div class="one-search-area ptb-100">
    <div class="container">
        <div class="search-wrap">
            <div class="search-content">
                <div class="one-section-title">
                    <span class="sub-title">WHY CHOOSE US</span>
                    <h2>Among Them We’re</h2>
                </div>

                <form>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                        <label class="form-check-label" for="exampleRadios1">
                            Real Estate Agents
                        </label>
                    </div>

                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                        <label class="form-check-label" for="exampleRadios2">
                            Loan Officer
                        </label>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="First Name">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Last Name">
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group">
                                <select class="form-control">
                                    <option>License State</option>
                                    <option>Another option</option>
                                    <option>A option</option>
                                    <option>Potato</option>
                                </select>	
                            </div>
                        </div>
                    </div>

                    <div class="search-link">
                        <button type="submit">Search Your Result</button>
                    </div>
                </form>
            </div>

            <div class="search-img">
                <img src="assets/img/home-one/search-man.png" alt="Search">
            </div>
        </div>
    </div>
</div>-->

<!--
<section class="one-testimonial-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">TESTIMONIALS</span>
            <h2>People Are Saying</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="testimonial-item">
                    <div class="testimonial-top">
                        <div class="test-img">
                            <img src="assets/img/home-one/testimonial1.png" alt="Testimonial">
                            <i class='bx bxs-quote-alt-left'></i>
                        </div>
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <h3>ERIC HUANG</h3>
                        <span>Homeowners</span>
                    </div>

                    <div class="testimonial-bottom">
                        <span>Friend For Life!</span>
                        <p>Lorem ipsum dolor sit ame concetur adipisicing elit, sed do eiusmod or incididut labore</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="testimonial-item active">
                    <div class="testimonial-top">
                        <div class="test-img">
                            <img src="assets/img/home-one/testimonial2.png" alt="Testimonial">
                            <i class='bx bxs-quote-alt-left'></i>
                        </div>
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <h3>DONNEY JON</h3>
                        <span>Enton gov inc.</span>
                    </div>

                    <div class="testimonial-bottom">
                        <span>Awesome Agency</span>
                        <p>Lorem ipsum dolor sit ame concetur adipisicing elit, sed do eiusmod or incididut labore</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="testimonial-item">
                    <div class="testimonial-top">
                        <div class="test-img">
                            <img src="assets/img/home-one/testimonial3.png" alt="Testimonial">
                            <i class='bx bxs-quote-alt-left'></i>
                        </div>
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <h3>EDWARD H. BURNBAUM</h3>
                        <span>A. Schwartz & Associates</span>
                    </div>

                    <div class="testimonial-bottom">
                        <span>Recommending Mortgage</span>
                        <p>Lorem ipsum dolor sit ame concetur adipisicing elit, sed do eiusmod or incididut labore</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->

<!--
<div class="one-faq-area ptb-100">
    <div class="container">
        <div class="faq-content">
            <ul class="accordion">
                <li>
                    <a>How do you qualify for a loan?</a>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                </li>
                <li>
                    <a>A Good Lender Will Clearly Explain Mortgage?</a>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                </li>
                <li>
                    <a>Can You Get A Mortgage Without A Credit Score?</a>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                </li>
                <li>
                    <a>How Much Home Can You Afford?</a>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                </li>
            </ul>
        </div>
    </div>

    <div class="faq-img">
        <img src="assets/img/home-one/faq-men.png" alt="FAQ">
    </div>
</div>
-->

<!--<div id="blog" class="one-blog-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">PORTAFOLIO</span>
            <h2>Nuestros Portafolio</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/"><img src="assets/img/home-one/blog1.jpg" alt="Blog"></a>
                        <span>Empresa 1</span>
                    </div>
                    <div class="blog-bottom">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2023</li>
                            <li><i class='bx bx-comment-detail'></i> 5 Proyectos</li>
                        </ul>
                        <h3><a routerLink="/">8 Ways To Make Your Home A Smart Home</a></h3>
                        <img src="assets/img/home-one/blog1.png" alt="Blog">
                        <p><a routerLink="/">Persona 1</a>, Author</p>
                    </div>
                </div>
            </div>
            
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/"><img src="assets/img/home-one/blog2.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>
                    <div class="blog-bottom">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2023</li>
                            <li><i class='bx bx-comment-detail'></i> 0 Comment</li>
                        </ul>
                        <h3><a routerLink="/">8 Ways To Make Your Home A Smart Home</a></h3>
                        <img src="assets/img/home-one/blog2.png" alt="Blog">
                        <p><a routerLink="/">Movin Jex</a>, Author</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/"><img src="assets/img/home-one/blog3.jpg" alt="Blog"></a>
                        <span>Mortgage</span>
                    </div>
                    <div class="blog-bottom">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2023</li>
                            <li><i class='bx bx-comment-detail'></i> 0 Comment</li>
                        </ul>
                        <h3><a routerLink="/">5 Ways To build Yourself By Hard Working</a></h3>
                        <img src="assets/img/home-one/blog3.png" alt="Blog">
                        <p><a routerLink="/">Jems</a>, Author</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>-->

<div id="contact" class="one-contact-area">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-5 p-0">
                <div class="contact-img">
                    <img src="assets/img/home-one/contact.jpg" alt="Conatact">
                </div>
            </div>
            
            <div class="col-lg-7 p-0">
                <div class="contact-content">
                    <div class="one-section-title">
                        <span class="sub-title">CONTACTO</span>
                        <h2>Jaime Andrés Correa</h2>
                    </div>

                    <form id="contactForm">
                        <div class="row m-0">                        
                            <div class="col-md-12 col-lg-12">
                                <div class="row m-0">                                  
                                    <div class="col-sm-8 col-lg-8">
                                        <div class="contact-emergency">
                                            <i class='bx bx-phone-call'></i>
                                            <span>Solutions Architect</span>
                                            <span>jcorremo@coode.tech</span>
                                            <a href="tel:+57 321 316 9400">+57 321 316 9400</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<section class="one-subscribe-area">
    <div class="">
        <div class="subscribe-wrap">
            <h2>¿Quieres recibir actualizaciones de lo que viene?</h2>
            <form class="newsletter-form">
                <input type="email" class="form-control" placeholder="Ingresa tu email" name="EMAIL" required autocomplete="off">
                <button class="btn subscribe-btn" type="submit">Suscribete</button>
            </form>
        </div>
    </div>
</section>-->

<footer class="one-footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo-two.png" alt="Logo"></a>
                        <br>
                        <p>Copyright © 2024 COODE</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                
            </div>

            <div class="col-sm-6 col-lg-3">
                
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-address">
                        <h3>Contáctenos</h3>

                        <ul>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+4498886660000">+57 321 316 9400</a>
                            </li>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+5498586560350">+52 1 55 8022 9478</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:hello@levi.com">omar@coode.tech</a>
                            </li>
                            <li>
                                <i class='bx bx-message-square-detail'></i>
                                <a href="mailto:info@levi.com">jcorremo@coode.tech</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--
        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <ul>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/paypal.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/skrill.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/master-card2.jpg" alt="Method"></a></li>
                            <li><a href="#" target="_blank"><img src="assets/img/home-one/visa2.jpg" alt="Method"></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item">
                        <p>© Levi is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                    </div>
                </div>
            </div>
        </div>
        -->
    </div>
</footer>